import None from '../../assets/image/saas/faq-empty.png';
import Bell from '../../assets/image/saas/Bpifrance_Digital_bell.png';
import Lens from '../../assets/image/saas/Bpifrance_Digital_lens.png';
import Calculate from '../../assets/image/saas/Bpifrance_Digital_calculate.png';
import Tag from '../../assets/image/saas/Bpifrance_Digital_tag.png';

export const SERVICE_ITEMS = [
  {
    icon: 'flaticon-calculator',
    title: 'Trésorerie',
  },
  {
    icon: 'flaticon-price',
    title: 'Catégories',
  },
  {
    icon: 'flaticon-bell',
    title: 'Alertes',
  },
  {
    icon: 'flaticon-eye',
    title: 'Prévisionnel',
  },
];

export const SERVICE_FEATURES_ITEMS = [
  {
    icon: Calculate,
    title: 'Trésorerie',
  },
  {
    icon: Tag,
    title: 'Catégories',
  },
  {
    icon: Bell,
    title: 'Alertes',
  },
  {
    icon: Lens,
    title: 'Prévisionnel',
  },
];

export const Faq = [
  {
    title: "Pourquoi s'inscrire sur Ma Tréso, dès maintenant ?",
    description:
      'Ma Tréso vous permet d’ores et déjà de consulter le solde global de votre trésorerie, l’évolution de vos flux entrants et sortants, ou encore de vous fixer des objectifs prévisionnels... Et ce n’est qu’un début ! Nous souhaitons faire évoluer ce service avec vous, au gré de vos besoins et de vos précieux retours d’expérience.',
    description2: 'A vous de nous les suggérer !',
    image: None,
    alternatetext: ' ',
  },
  {
    title: 'Puis-je supprimer mon compte librement ?',
    description:
      'La création de votre compte est gratuite et sans engagement. Vous pouvez à tout moment décider de supprimer votre compte utilisateur ou désagréger vos comptes bancaires. Nous ne garderons aucune trace de vos données.',
    image: None,
    alternatetext: ' ',
  },
  {
    title: 'Quel sera le coût de Ma Tréso ?',
    description:
      "Ma Tréso est actuellement disponible directement via l'abonnement Bpifrance en ligne. Les 3 premiers mois sont offerts pour tester l'ensemble des services proposés (dont Ma Tréso). Vous êtes libre de vous faire votre propre opinion : vous ne serez facturé qu'à partir du 91ème jour. Souscrivez dès à présent en suivant <a href='https://bel.bpifrance.fr/' target='_blank' style='font-weight: bold; text-decoration: underline;'>ce lien.</a>",
    image: None,
    alternatetext: ' ',
  },
  {
    title: 'Dans quel but mes données sont-elles collectées ?',
    description:
      "Les données personelles que nous collectons ont pour seule finalité de vous permettre de synchroniser vos comptes, gérer votre trésorerie efficacement et profiter de suggestions avisées. Personne n'a accès à vos identifiants, ni à vos données financières.",
    image: None,
    alternatetext: ' ',
  },
  {
    title: 'Comment ajouter mes comptes bancaires ?',
    description:
      "Notre partenaire Powens est connecté à plus de 300 banques ; seuls les comptes en euros sont éligibles. Pour agréger un compte, il vous suffit de vous rendre sur votre tableau de bord et de cliquer sur le bouton « Ajouter un compte ». Complétez ensuite les informations relatives à votre banque (vos identifiants sont les mêmes que ceux que vous utilisez pour vous connecter à votre banque en ligne) et patientez quelques secondes le temps que vos données soient agrégées. Nous affichons les mêmes informations que votre banque : vous devez donc attendre que celles-ci soient disponibles sur votre interface bancaire pour qu'elles apparaissent dans Ma Tréso.",
    description2:
      'Si vous rencontrez la moindre difficulté ou ne trouvez pas votre banque, n’hésitez pas à nous contacter à matreso@bpifrance.fr',
    image: None,
    alternatetext: ' ',
  },
];

export const Cgu = [
  {
    id: 1,
    expend: true,
    title: 'Préambule',
    description:
      'Bpifrance tire parti de la Directive des Services de Paiement (DSP2), entrée en vigueur le 14 septembre 2019, pour développer de nouveaux services et s’affirmer un peu plus comme une « Fintech de place ». L’objectif de cette plateforme, lancée le lundi 8 avril 2019 sur un premier panel d’utilisateurs tests, est de permettre aux chefs d’entreprises et aux directeurs financiers d’agréger en moins de 5 minutes leurs comptes bancaires, c’est à dire de les regrouper, pour pouvoir les gérer plus efficacement. Celle-ci n’est, pour l’instant, pas liée à Bpifrance en Ligne, e-Treso ou tout autre service de Bpifrance.',
  },
  {
    id: 2,
    title: 'Définitions',
    description:
      'Ce MVP, comprenez produit minimum viable, vous permet d’ores et déjà de consulter le solde global de votre trésorerie, l’évolution de vos flux entrants et sortants, ou encore de suivre vos dépenses mensuelles... Et ce n’est qu’un début ! Nous souhaitons faire évoluer ce service avec vous, en fonction de vos besoins et de vos retours d’expérience, jusqu’à sa sortie officielle en fin d’année 2019. Plus qu’un simple agrégateur, celui-ci a vocation à devenir un assistant financier intelligent qui vous permettra de garder toujours un coup d’avance sur votre trésorerie, d’effectuer des virements multiples, de catégoriser vos dépenses, de simplifier vos démarches comptables, et plus encore... Nous vous proposerons chaque semaine de nouvelles fonctionnalités. C’est à vous de décider 😉',
  },
  {
    id: 3,
    title: "Présentation de l'interface",
    description:
      'La création de votre compte est 100% gratuite et sans engagement. Vous pouvez à tout moment décider de quitter notre programme de bêta-testeurs et supprimer votre compte. Nous ne garderons aucune trace de vos données.',
  },
  {
    id: 4,
    title: 'Objet',
    description:
      'Vous restez maître de vos données : celles-ci sont agrégées par Powens, notre partenaire certifié par l’ACPR, et hébergées en Europe sur des disques chiffrés garantissant le même niveau de sécurité que le site web de votre banque. Personne n’a accès à vos identifiants. Libre à vous de désagréger un compte quand bon vous semble. Pour en savoir plus, vous pouvez vous reporter aux Conditions Générales d’Utilisation.',
  },
  {
    id: 5,
    title: 'Documents',
    description:
      'N’hésitez pas : si vous avez la moindre question, rencontrez des difficultés ou souhaitez simplement discuter avec nous de cet ambitieux projet, envoyez-nous un email à dsp2@bpifrance.fr Vous pouvez également nous partager vos idées d’amélioration directement depuis votre tableau de bord, en cliquant sur le bouton « Votre avis » situé sur sa droite.',
  },
  {
    id: 6,
    title: "Acceptation et modification des conditions générales d'utilisation",
    description: 'Notre feuille de route sera bientôt publiée.',
  },
  {
    id: 7,
    title:
      'Acceptation des conditions de collecte et de traitement des données a caractère personnel',
    description:
      'Notre service est connecté à plus de 300 banques. Pour agréger un compte, rendez-vous sur votre tableau de bord et cliquez sur le bouton « Agréger un compte ». Complétez ensuite les informations relatives à votre banque (vos identifiants sont les mêmes que ceux que vous utilisez pour vous connecter à votre banque en ligne) et patientez quelques secondes le temps que vos données soient agrégées. Si vous rencontrez la moindre difficulté ou ne trouvez pas votre banque, n’hésitez pas à nous contacter à dsp2@contact-bpifrance.fr.',
  },
  {
    id: 8,
    title: 'Accès au compte utilisateur',
    description:
      'Notre service est connecté à plus de 300 banques. Pour agréger un compte, rendez-vous sur votre tableau de bord et cliquez sur le bouton « Agréger un compte ». Complétez ensuite les informations relatives à votre banque (vos identifiants sont les mêmes que ceux que vous utilisez pour vous connecter à votre banque en ligne) et patientez quelques secondes le temps que vos données soient agrégées. Si vous rencontrez la moindre difficulté ou ne trouvez pas votre banque, n’hésitez pas à nous contacter à dsp2@contact-bpifrance.fr.',
  },
  {
    id: 9,
    title: 'Durée',
    description:
      'Notre service est connecté à plus de 300 banques. Pour agréger un compte, rendez-vous sur votre tableau de bord et cliquez sur le bouton « Agréger un compte ». Complétez ensuite les informations relatives à votre banque (vos identifiants sont les mêmes que ceux que vous utilisez pour vous connecter à votre banque en ligne) et patientez quelques secondes le temps que vos données soient agrégées. Si vous rencontrez la moindre difficulté ou ne trouvez pas votre banque, n’hésitez pas à nous contacter à dsp2@contact-bpifrance.fr.',
  },
  {
    id: 10,
    title: 'Sécurité',
    description:
      'Notre service est connecté à plus de 300 banques. Pour agréger un compte, rendez-vous sur votre tableau de bord et cliquez sur le bouton « Agréger un compte ». Complétez ensuite les informations relatives à votre banque (vos identifiants sont les mêmes que ceux que vous utilisez pour vous connecter à votre banque en ligne) et patientez quelques secondes le temps que vos données soient agrégées. Si vous rencontrez la moindre difficulté ou ne trouvez pas votre banque, n’hésitez pas à nous contacter à dsp2@contact-bpifrance.fr.',
  },
  {
    id: 11,
    title: 'Accès au compte utilisateur',
    description:
      'Notre service est connecté à plus de 300 banques. Pour agréger un compte, rendez-vous sur votre tableau de bord et cliquez sur le bouton « Ajouter un compte ». Complétez ensuite les informations relatives à votre banque (vos identifiants sont les mêmes que ceux que vous utilisez pour vous connecter à votre banque en ligne) et patientez quelques secondes le temps que vos données soient agrégées. Si vous rencontrez la moindre difficulté ou ne trouvez pas votre banque, n’hésitez pas à nous contacter à dsp2@contact-bpifrance.fr.',
  },
  {
    id: 12,
    title: 'Accès au compte utilisateur',
    description:
      'Notre service est connecté à plus de 300 banques. Pour agréger un compte, rendez-vous sur votre tableau de bord et cliquez sur le bouton « Agréger un compte ». Complétez ensuite les informations relatives à votre banque (vos identifiants sont les mêmes que ceux que vous utilisez pour vous connecter à votre banque en ligne) et patientez quelques secondes le temps que vos données soient agrégées. Si vous rencontrez la moindre difficulté ou ne trouvez pas votre banque, n’hésitez pas à nous contacter à dsp2@contact-bpifrance.fr.',
  },
  {
    id: 13,
    title: 'Accès au compte utilisateur',
    description:
      'Notre service est connecté à plus de 300 banques. Pour agréger un compte, rendez-vous sur votre tableau de bord et cliquez sur le bouton « Agréger un compte ». Complétez ensuite les informations relatives à votre banque (vos identifiants sont les mêmes que ceux que vous utilisez pour vous connecter à votre banque en ligne) et patientez quelques secondes le temps que vos données soient agrégées. Si vous rencontrez la moindre difficulté ou ne trouvez pas votre banque, n’hésitez pas à nous contacter à dsp2@contact-bpifrance.fr.',
  },
  {
    id: 14,
    title: 'Accès au compte utilisateur',
    description:
      'Notre service est connecté à plus de 300 banques. Pour agréger un compte, rendez-vous sur votre tableau de bord et cliquez sur le bouton « Agréger un compte ». Complétez ensuite les informations relatives à votre banque (vos identifiants sont les mêmes que ceux que vous utilisez pour vous connecter à votre banque en ligne) et patientez quelques secondes le temps que vos données soient agrégées. Si vous rencontrez la moindre difficulté ou ne trouvez pas votre banque, n’hésitez pas à nous contacter à dsp2@contact-bpifrance.fr.',
  },
  {
    id: 15,
    title: 'Accès au compte utilisateur',
    description:
      'Notre service est connecté à plus de 300 banques. Pour agréger un compte, rendez-vous sur votre tableau de bord et cliquez sur le bouton « Agréger un compte ». Complétez ensuite les informations relatives à votre banque (vos identifiants sont les mêmes que ceux que vous utilisez pour vous connecter à votre banque en ligne) et patientez quelques secondes le temps que vos données soient agrégées. Si vous rencontrez la moindre difficulté ou ne trouvez pas votre banque, n’hésitez pas à nous contacter à dsp2@contact-bpifrance.fr.',
  },
  {
    id: 16,
    title: 'Accès au compte utilisateur',
    description:
      'Notre service est connecté à plus de 300 banques. Pour agréger un compte, rendez-vous sur votre tableau de bord et cliquez sur le bouton « Agréger un compte ». Complétez ensuite les informations relatives à votre banque (vos identifiants sont les mêmes que ceux que vous utilisez pour vous connecter à votre banque en ligne) et patientez quelques secondes le temps que vos données soient agrégées. Si vous rencontrez la moindre difficulté ou ne trouvez pas votre banque, n’hésitez pas à nous contacter à dsp2@contact-bpifrance.fr.',
  },
  {
    id: 17,
    title: 'Accès au compte utilisateur',
    description:
      'Notre service est connecté à plus de 300 banques. Pour agréger un compte, rendez-vous sur votre tableau de bord et cliquez sur le bouton « Agréger un compte ». Complétez ensuite les informations relatives à votre banque (vos identifiants sont les mêmes que ceux que vous utilisez pour vous connecter à votre banque en ligne) et patientez quelques secondes le temps que vos données soient agrégées. Si vous rencontrez la moindre difficulté ou ne trouvez pas votre banque, n’hésitez pas à nous contacter à dsp2@contact-bpifrance.fr.',
  },
  {
    id: 18,
    title: 'Accès au compte utilisateur',
    description:
      'Notre service est connecté à plus de 300 banques. Pour agréger un compte, rendez-vous sur votre tableau de bord et cliquez sur le bouton « Agréger un compte ». Complétez ensuite les informations relatives à votre banque (vos identifiants sont les mêmes que ceux que vous utilisez pour vous connecter à votre banque en ligne) et patientez quelques secondes le temps que vos données soient agrégées. Si vous rencontrez la moindre difficulté ou ne trouvez pas votre banque, n’hésitez pas à nous contacter à dsp2@contact-bpifrance.fr.',
  },
  {
    id: 19,
    title: 'Accès au compte utilisateur',
    description:
      'Notre service est connecté à plus de 300 banques. Pour agréger un compte, rendez-vous sur votre tableau de bord et cliquez sur le bouton « Agréger un compte ». Complétez ensuite les informations relatives à votre banque (vos identifiants sont les mêmes que ceux que vous utilisez pour vous connecter à votre banque en ligne) et patientez quelques secondes le temps que vos données soient agrégées. Si vous rencontrez la moindre difficulté ou ne trouvez pas votre banque, n’hésitez pas à nous contacter à dsp2@contact-bpifrance.fr.',
  },
  {
    id: 20,
    title: 'Accès au compte utilisateur',
    description:
      'Notre service est connecté à plus de 300 banques. Pour agréger un compte, rendez-vous sur votre tableau de bord et cliquez sur le bouton « Agréger un compte ». Complétez ensuite les informations relatives à votre banque (vos identifiants sont les mêmes que ceux que vous utilisez pour vous connecter à votre banque en ligne) et patientez quelques secondes le temps que vos données soient agrégées. Si vous rencontrez la moindre difficulté ou ne trouvez pas votre banque, n’hésitez pas à nous contacter à dsp2@contact-bpifrance.fr.',
  },
  {
    id: 21,
    title: 'Loi applicable',
    description:
      'Notre service est connecté à plus de 300 banques. Pour agréger un compte, rendez-vous sur votre tableau de bord et cliquez sur le bouton « Agréger un compte ». Complétez ensuite les informations relatives à votre banque (vos identifiants sont les mêmes que ceux que vous utilisez pour vous connecter à votre banque en ligne) et patientez quelques secondes le temps que vos données soient agrégées. Si vous rencontrez la moindre difficulté ou ne trouvez pas votre banque, n’hésitez pas à nous contacter à dsp2@contact-bpifrance.fr.',
  },
];

export const Features = [
  {
    id: 1,
    icon: 'flaticon-creative',
    title: 'Powerful Features',
    description:
      'Automate time consuming tasks like organising expenses ,tracking your time and following up with clients ',
  },
  {
    id: 2,
    icon: 'flaticon-briefing',
    title: 'Easy Invoicing',
    description:
      'Want to surprice your clients with professional looking invoices ? Then you are some clicks behind .',
  },
  {
    id: 3,
    icon: 'flaticon-flask',
    title: 'Easy To Use',
    description:
      'Very Simple and intuitive. So you have to spend less time in paperwork and impress your customer with looks',
  },
];

export const showPrivacyCenter = () => {
  return window.tC && window.tC.privacyCenter && window.tC.privacyCenter.showPrivacyCenter();
};

export const Footer_Data = [
  {
    title: 'Ma Tréso',
    menuItems: [
      {
        url: '/app/new-onboarding',
        staticLink: true,
        text: 'Se connecter',
      },
      {
        url: '#banner_section',
        text: 'Fonctionnalités',
      },
      {
        url: '#timeline',
        text: 'Vision',
      },
      {
        url: '#faq_section',
        text: 'FAQ',
      },
    ],
  },
  {
    title: 'Bpifrance',
    menuItems: [
      {
        url: 'https://www.bpifrance.fr/Qui-sommes-nous/Notre-mission',
        staticLink: true,
        text: 'À propos',
      },
      {
        url: 'https://www.bpifrance.fr/Toutes-nos-solutions',
        staticLink: true,
        text: 'Solutions',
      },
      {
        url: 'https://www.bpifrance.fr/A-la-une/Actualites',
        staticLink: true,
        text: 'Actualités',
      },
    ],
  },
  {
    title: 'Liens utiles',
    menuItems: [
      {
        url: 'https://matreso.stonly.com/kb/fr',
        staticLink: true,
        text: "Centre d'aide",
      },
      {
        url: 'https://www.bpifrance.fr/Mentions-legales',
        staticLink: true,
        text: 'Mentions Légales',
      },
      {
        url: '/app/assets/documents/CGU_MaTreso_Avril_2024.pdf',
        staticLink: true,
        text: 'CGU',
      },
      {
        staticLink: false,
        text: 'Gestion des cookies',
        callScript: true,
        clickAction: showPrivacyCenter
      },

    ],
  },
  {
    title: 'Contact',
    menuItems: [
      {
        url: 'mailto:matreso@bpifrance.fr',
        staticLink: true,
        text: 'matreso@bpifrance.fr',
      },
    ],
  },
];

export const Service = [
  {
    id: 1,
    icon: 'flaticon-briefing',
    title: 'App Development',
    description:
      'We are specialized at custom Saas Application Development and special features .',
  },
  {
    id: 2,
    icon: 'flaticon-trophy',
    title: '10 Times Award',
    description:
      'We are globally recognised for our services and won a lot of prices around the world .',
  },
  {
    id: 3,
    icon: 'flaticon-atom',
    title: 'Cloud Stroage',
    description:
      'LiteSpeed Web Server known for its high performance and low resource consumption.',
  },
  {
    id: 4,
    icon: 'flaticon-ruler',
    title: 'Client Satisfaction',
    description:
      'Client Satisfaction is our first priority and We are best at it .Keep In Touch.',
  },
  {
    id: 5,
    icon: 'flaticon-creative',
    title: 'UX Planning',
    description:
      'We provide the best UI/UX Design by following the latest trends of the market  .',
  },
  {
    id: 6,
    icon: 'flaticon-conversation',
    title: 'Customer Support',
    description:
      'A Dedicated support team is always ready to provide best support to our customers.',
  },
];

export const Timeline = [
  {
    title: 'Inscrivez-vous, tout simplement',
    description:
      "Saisissez votre raison sociale ou votre numéro de SIREN, et c'est tout... Nous récupérons automatiquement vos documents. Vous n'avez qu'à les valider.",
  },
  {
    title: 'Ajoutez vos comptes bancaires',
    description:
      'Regroupez les comptes utilisés par votre société à l’aide des identifiants web de vos banques en ligne. C’est simple comme bonjour et sans engagement.',
  },
  {
    title: 'Donnez votre avis',
    description:
      'Nous souhaitons faire évoluer ce service avec vous, au gré de vos besoins et de vos précieux retours d’expérience. Nous vous proposerons régulièrement de nouvelles évolutions !',
  },
];

// export const MENU_ITEMS = [
//   {
//     label: "Ma Tréso",
//     path: "#banner_section",
//     offset: "70"
//   },
//   {
//     label: "Fonctionnalités",
//     path: "#section1",
//     offset: "70"
//   },
//   {
//     label: "Vision",
//     path: "#timeline",
//     offset: "70"
//   },
//   {
//     label: "FAQ",
//     path: "#faq_section",
//     offset: "70"
//   },
//   {
//     label: "Se connecter",
//     staticLink:true,
//     path: "/sign-up/login",
//     offset: "70"
//   },
//   {
//     label: "CRÉER UN COMPTE",
//     staticLink:true,
//     highlight:true,
//     path: "https://bpifrance-lehub.typeform.com/to/mIf84X",
//     offset: "140"
//   }
// ];

export const MENU_ITEMS = [
  {
    label: 'Ma Tréso',
    path: '#banner_section',
    offset: '70',
  },
  {
    label: 'Fonctionnalités',
    path: '#section1',
    offset: '70',
  },
  {
    label: 'Vision',
    path: '#timeline',
    offset: '70',
  },
  {
    label: 'Aide',
    path: '#faq_section',
    offset: '70',
  },
];
export const MENU_LEFT_ITEMS = [
  {
    label: 'Ma Tréso',
    path: '#banner_section',
    offset: '70',
  },
  {
    label: 'Fonctionnalités',
    path: '#section1',
    offset: '70',
  },
  {
    label: 'Vision',
    path: '#timeline',
    offset: '70',
  },
  {
    label: 'FAQ',
    path: '#faq_section',
    offset: '70',
  },
];
export const MENU_RIGHT_ITEMS = [
  {
    label: '',
    path: '',
    offset: '70',
  },
  {
    label: 'Nous contacter',
    path: 'mailto:matreso@bpifrance.fr',
    staticLink: true,
    offset: '70',
  },
];

export function copyrightText() {
  const year = (new Date()).getFullYear();
  return `${year} © Copyright Bpifrance. `;
}
